input {
  box-sizing: border-box;
  outline: none;
  border: 1px solid gray;
  width:280px;
  height: 40px;
  padding: 10px;
  border-radius: 3px;
}
input:focus {
  outline: none;
}

ul {
  position: relative;
  z-index: 10000;
  width: 280px;
  max-height: 140px;
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: -10px;
  border: 1px solid rgb(173, 173, 173);

}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.list-item {
  box-sizing: border-box;
  height: 70px;
  width: 280px;
  border: 0.5px solid rgb(173, 173, 173);
  border-left: 0;
  border-right: 0;
  border-bottom-color: #caf0f8;
  border-top-color: #caf0f8;
  background-color: white;
}
.list-item:hover {
  background-color: #eafafd;
}

.list-item.preselected {
background-color: #c6f2fa;
}

.list-item-wrapper {
  display: flex;
  flex-direction: row;
}

.short-name {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 35px;
  width: 35px;
  margin: 15px;
  border-radius: 5px;
}
.fullname-and-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fullname-and-email p{
  margin: 0;
}
.fullname-and-email .fullname{
  font-size: 15px;
  color: rgb(26, 26, 26);
}
.fullname-and-email .email{
  font-size: 12px;
  color: rgb(173, 173, 173);
}

li:nth-child(even) .short-name{
  background-color: #0bbadd;
}
li:nth-child(odd) .short-name{
  background-color: #ff7315;
}

.arrow-icon {
  position: relative;
  right: -260px;
  top: -30px;
}
.arrow-up {
  transform: rotateZ(-180deg);
}

