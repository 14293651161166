.App {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.dropdown-wrapper {
  box-sizing: border-box;
  padding: 10px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 70px;
}
.App .manager-details {
  align-self: center;
  width: auto;
  margin-top: 200px;
  text-align: center;
  padding: 10px;
  border: 0.5px solid rgb(190, 190, 190);
  border-radius: 3px;
}